// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components';
import {whr_orange, whr_blue} from '../assets/colours';

function ContactForm() {

  const [state, handleSubmit] = useForm("xpzkzvdd");

  if (state.succeeded) {
      return <Message>Thanks for your message - we'll be in touch!</Message>;
  }

  return (
      <Form onSubmit={handleSubmit}>
        <label htmlFor="name">
            Name *
        </label>
        <input
            id="name"
            type="name" 
            name="name"
            required
        />
        <ValidationError 
            prefix="Name" 
            field="name"
            errors={state.errors}
        />
        <label htmlFor="email">
            Email Address *
        </label>
        <input
            id="email"
            type="email" 
            name="email"
            required
        />
        <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
        />
        <label htmlFor="contact_no">
            Contact Number:
        </label>
        <input 
            id = "contact_no"
            type = "tel"
            name = "contact_no"
        />
        <ValidationError 
            prefix="Tel" 
            field="contact_no"
            errors={state.errors}
        />

        <label htmlFor="enquiry">
            Enquiry *
        </label>
        
        <textarea
            id="enquiry"
            name="enquiry"
            required
            rows = "10"
        />

        <ValidationError 
            prefix="Message" 
            field="enquiry"
            errors={state.errors}
        />

        <button type="submit" disabled={state.submitting}>
            Submit
        </button>
    </Form>
  );
}

const Message = styled.p`
    font-size:18px;
    font-weight:bold;
`

const Form = styled.form`

    .success{
        font-size:18px;
        font-weight:bold;
    }

    label{
        display:block;
        width:100%;
        font-family:"Karla", sans-serif;
        margin-bottom:4px;
    }
    input, textarea{
        display:block;
        width:100%;
        margin-bottom:8px;
        background-color:#F3F3F3;
        border:0;
        border-radius:0;
        font-family:"Karla", sans-serif;
    }

    input{
        line-height:35px;
    }

    button{
        cursor:pointer;
        font-family:"Karla", sans-serif;
        background:${whr_orange};
        transition:0.7s;
        border:0;
        padding:12px 24px;
        margin-top:16px;
        color:white;
        font-weight:bold;

        &:hover{
            background:${whr_blue};
            transition:0.7s;
        }
    }

`

export default ContactForm;