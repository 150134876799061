import React, { useState } from "react"
import { content, content_inner, featured_image } from "../assets/global"

import ContactForm from "../components/contact_form"
import Image from "gatsby-image"
import Layout from "../components/layout"
import PageHeader from "../components/page_header"
import QuickMenu from "../components/quick_menu"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"
import { whr_blue } from "../assets/colours"

const ContactPage = ({ data, location }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  const quick_menu_links = data?.wpPage?.pageTemplateContact?.contactSections.map(
    ({ contactSection: { title } }) => {
      return {
        title: title,
        id: title.replace(/\s+/g, "-")?.toLowerCase(),
      }
    }
  )

  const featuredImage = {
    fluid: data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.wpPage.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout
      title={data.wpPage.seo.title}
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      fullWidthMenu={false}
    >
      <Seo
        description={data.wpPage.seo.metaDesc}
        title={data.wpPage.seo.title}
      />
      <FeaturedImage>
        <Image
          fluid={featuredImage.fluid}
          alt={featuredImage.alt}
          style={{ height: "100%" }}
        />
      </FeaturedImage>
      <Content>
        <PageHeader title={data.wpPage.title} />
        <QuickMenu
          jump_text="Jump To"
          menu_items={quick_menu_links}
        ></QuickMenu>
        <ContentInner>
          {data?.wpPage?.pageTemplateContact?.contactSections?.map(
            ({ contactSection: { title, content } }) => {
              return (
                <ContactSection
                  key={title}
                  id={title.replace(/\s+/g, "-")?.toLowerCase()}
                >
                  <h2>{title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(content, {
                        allowedAttributes: {
                          a: ["href", "name", "target"],
                          li: ["class"],
                        },
                        allowedSchemes: [
                          "http",
                          "https",
                          "ftp",
                          "mailto",
                          "tel",
                          "sms",
                        ],
                      }),
                    }}
                  ></div>
                </ContactSection>
              )
            }
          )}
        </ContentInner>
      </Content>
    </Layout>
  )
}

export default ContactPage

const FeaturedImage = styled.div`
  ${featured_image};
`

const Content = styled.article`
  ${content};
`
const ContentInner = styled.div`
  ${content_inner};
`
const ContactSection = styled.section`
  > h2 {
    color: ${whr_blue};
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  li.call:before {
    content: "📞";
  }

  li.text:before {
    content: "💬";
  }

  li.email:before {
    content: "✉️";
  }

  li.website:before {
    content: "🌐";
  }

  li.write:before {
    content: "✏️";
  }
`

export const pageQuery = graphql`
  {
    wp {
      themeOptions {
        themeOptions {
          themeLogo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          alburyAddress {
            title
            url
          }
          contactEmail {
            title
            url
          }
          contactNumber {
            url
            title
          }
          torquayAddress {
            title
            url
          }
          facebook {
            title
            url
          }
          instagram {
            title
            url
          }
        }
      }
      generalSettings {
        title
        description
      }
    }
    wpPage(template: { templateName: { eq: "Contact Template" } }) {
      pageTemplateContact {
        contactSections {
          contactSection {
            content
            title
          }
        }
      }
      seo {
        metaDesc
        title
      }
      id
      title
      slug
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
